<template>
    <div v-if="!isLoadingBil">
        <section class="aa-contenedor-basico fade-in">
            <div class="aa-contenedor-basico-portada">
                <div class="portada"> </div>
            </div>

            <h2 class="aa-text-h2 text-start px-2"> CONVERSATION ASSISTANTS</h2>
            <div class="aa-cotenedor-basico-cuerpo my-1 px-2">
                
                <div class="intro-text">
                    <div class="text-start text-wrap mt-2" v-html="bilingue.escuela"></div>
                </div>
                <div class="auxiliares-list">
                    
                    <div class="curso-auxiliar" v-for="auxiliar in bilingue.auxiliares" :key="auxiliar.id">
                    <div class="card-auxiliar my-2 p-1">
                        <div class="avatar-aux">
                            <img :src="auxiliar.foto" alt="Avatar Auxiliar" :title="auxiliar.nombre"/>
                        </div>
                        <div class="info-aux text-start">
                            <div class="aux-nombre">
                                {{auxiliar.nombre}} 
                                <br>
                                <small class="fw-500">
                                {{auxiliar.curso}}
                                </small>
                            </div>
                        <div class="aux-acciones text-end">
                                <a :href="auxiliar.presentacion" target="_blank" v-if="auxiliar.presentacion">
                                    <img src="../../../assets/UI/descarga.svg" alt="Descargar Presentación" title="Descargar Presentación"/>
                                </a>
                                <router-link :to="{name:'recursosaux', params: {id: auxiliar.id}}" v-if="auxiliar.recursos > 0">
                                    <img src="../../../assets/UI/recursos.svg" alt="Ver Recursos" title="Ver Recursos"/>
                                </router-link>
                                <router-link :to="{name:'actividadesaux', params: {id: auxiliar.id}}" v-if="auxiliar.actividades > 0">
                                    <img src="../../../assets/UI/actividades.svg" alt="Ver Actividades" title="Ver Actividades"/>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    </div>  
                </div>
            </div>


        </section>
    </div>
    <div v-else>
        <PrensaSk />
    </div>
</template>

<script>
import useBilin from '../composables/useBilin'
import PrensaSk from '../components/skeleton/PrensaSk.vue'


export default {
    components: { PrensaSk },
    setup() {
        const { isLoadingBil, loaded, bilingue, getBasico, getAuxiliares } = useBilin()
        getAuxiliares()
        getBasico()
        
        return {
            isLoadingBil,
            bilingue,
            loaded,
            onLoaded: () => loaded.value = true,
        }
    }
}
</script>

<style lang="sass" scoped>
.aa-cotenedor-basico-cuerpo
    @include breakpoint(md)
        display: flex
        gap: 100px
        flex-direction: row
    .intro-text
        @include breakpoint(md)
            width: 60%
    .auxiliares-list
        @include breakpoint(md)
            width: 40%

.curso-auxiliar
    position: relative
    background-color: #F2F2F2
    border-radius: 20px

.card-auxiliar
    display: flex
    flex-direction: row
    gap: 10px
    align-items: center
    
    .avatar-aux
        img
            width: 80px
            height: 80px
            object-fit: cover
            object-position: top
            border-radius: 50%
    .aux-acciones
        a
            padding: 5px
            display: inline-flex
            flex-direction: row
            margin-left: 5px
            margin-right: 5px
            height: 30px
            width: 30px
            background-color: #fff
            border-radius: 50%
            justify-content: center
            align-items: center

            img
                height: 18px
    .info-aux
        display: flex
        flex-direction: column
        flex: 1

.portada
    background-image: url('../../../assets/img/fotos/conversacion.jpg')
</style>